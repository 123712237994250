import React, { Component } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
    updateUserProfileAction, loginActionForTrainComponent,
    getLanguageDataAction, clearStoreData, getEntityDetails
} from '../../redux/actions'
import { SimpleUserForm } from '../../components/Forms/SetPasswordForm'
import UserInfo from '../../components/SideBar/UserInfo.component';
import {
    success as successNotificationAction,
} from 'react-notification-system-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faUpload, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { notificationOpts } from '../../config';
import fetchMethodRequest from '../../config/service';
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile,
    deviceDetect,
    browserName,
    osName, osVersion, mobileModel, deviceType, getUA
} from "react-device-detect";
//session expiry modal
import SessionExpiryModal from './../../components/SessionExpiryModal'
import config from "./../../config/config";
import { Link } from 'react-router-dom';
import congratulation from '../../assets/images/successTrans.gif';
import { GetSessionValue } from '../../utils/sessionStorage';
let languageData = {}
const publicIp = require('public-ip');
const macaddress = require('macaddress');
class UserProfile extends Component {
    state = {
        isDisabled: true,
        btnLabel: 'Update Profile',
        userDetails: '',
        sessionExpiryModal: false,
        successFullCreated: false,
        deviceInfo: {},
    }

    onSubmit = (values, actions) => {
        console.log(values)
        let { updateUserProfile, successNotification, clearData } = this.props;
        if (values && values.merchantId && typeof (values.merchantId) == 'string') {
            let merchantIds = values.merchantId
            values.merchantId = merchantIds.split(',')
            delete values['merchantIds']
        }
        let body = {
            newPassword: '',
            confirmPassword: '',
            entityType: ''
        }
        if (values) {
            body['newPassword'] = values['password'];
            body['confirmPassword'] = values['confirmPassword'];
            body['entityType'] = 'user'

        }

        if (values['_id']) {
            body['_id'] = values['_id']
            body['brandId'] = values['brandId']
        }
        if (values['email']) body.email = values['email'];
        if (values['firstName']) body.firstName = values['firstName'];
        if (values['lastName']) body.lastName = values['lastName'];
        if (values['phone']) body.phone = values['phone'];

        let details = GetSessionValue('user')
        if (details && details['_id']) {
            let userFrom = sessionStorage.getItem("userFrom");
            let onboardFrom = "krowd";
            if(userFrom == "amex") {
                onboardFrom = "amex";
            }
            body.onboardFrom = onboardFrom;
            let url = `auth/createPassword?_id=${details['_id']}&adminReset=true`
            fetchMethodRequest('POST', url, body).then((response) => {
                console.log(response)
                if (response && response.data && response.data.respCode == 200) {
                    this.confirmSuccessful()
                    let deviceInfo = this.state.deviceInfo
                    let userData = {
                        'password': values['confirmPassword'],
                        'email': details['email'],
                        "entityType": "user",
                        'deviceInfo': deviceInfo,
                    }
                    this.props.submitGuestCampaignData(userData)
                }
            })
        }

        // updateUserProfile(body, (res, type) => {
        //     let sessionExpiryModal = false
        //     let sessionExpired = sessionStorage.getItem('sessionExpired')
        //     if (sessionExpired && (sessionExpired == 'true' || sessionExpired == true)) {
        //         sessionExpiryModal = true
        //     }
        //     this.setState({ sessionExpiryModal: sessionExpiryModal })
        //     let message
        //     let details
        //     if (res && res.details) {
        //         details = res.details
        //     }
        //     if (res && res.respMessage) {
        //         message = res.respMessage
        //     } else if (res && res.errorMessage) {
        //         message = res.errorMessage
        //     }
        //     if (actions) {
        //         actions.setSubmitting(false)
        //     }
        //     if (type == 'error') {
        //         if (actions) {
        //             actions.setStatus(message)
        //         }
        //         return;
        //     }
        //     this.updateUserProfile()
        //     setTimeout(() => {
        //         let notification = { ...notificationOpts }
        //         notification.message = message
        //         successNotification(notification)
        //     })
        //     if (details && details.status && details.status == 'deactivate') {
        //         clearData(res => {

        //         })
        //     }
        //     this.setState({ isDisabled: true })
        // })
    }
    getDataOfUser = async () => {

        let { user } = this.props
        console.log(user)
        if (user && user['proceesCompleted'] == true && user['stepThree'] == true && user['stepTwo'] == true) {
            await this.setState({
                successFullCreated: true
            })
        }
    }
    componentDidMount = async () => {
        this.getDataOfUser();
        // this.updateUserProfile()
        this.props.getLanguageData((res) => {

        })
        let deviceInfo = {}
        publicIp.v4().then((res) => {
            deviceInfo.ipAddress = res
        })
        // macaddress.all().then(async function (all) {
        //  await console.log(JSON.stringify(all, null, 2));
        // });
        deviceInfo.browserName = browserName
        deviceInfo.osName = osName
        deviceInfo.osVersion = osVersion
        deviceInfo.deviceType = deviceType
        this.setState({ deviceInfo: deviceInfo })
        await macaddress.all().then((res) => {
        })
    }

    updateUserProfile = () => {
        let { getEntityDetails } = this.props
        getEntityDetails(config.entityType, false, async (res, type) => {
            if (type == 'success') {
                await this.setState({ userDetails: res })
            } else {
                let sessionExpiryModal = false
                let sessionExpired = GetSessionValue('sessionExpired')
                if (sessionExpired && (sessionExpired == 'true' || sessionExpired == true)) {
                    sessionExpiryModal = true
                }
                await this.setState({ sessionExpiryModal: sessionExpiryModal })
            }
        })
    }

    formikRef = (e) => {
        this.formik = e
    }

    formikSubmit = () => {

        // if (this.formik) {
        //     this.formik.submitForm()
        //      this.confirmSuccessful()
        // }
        if (this.formik)
            this.formik.submitForm()


    }

    onToggleEdit = () => {
        let isDisabled = !this.state.isDisabled
        this.setState({ isDisabled })
    }
    confirmSuccessful = () => {
        this.setState({ successFullCreated: true })
    }
    getActionBtns = () => {
        if (this.state.isDisabled) {
            return (
                <div className="form-group text-center pt-3">
                    <button className="btn  btn-info btn-fill  ml-auto px-5 "
                        style={{ verticalAlign: 'bottom' }}
                        onClick={this.formikSubmit}
                    >
                        Login
                            </button>
                </div>
            )
        } else {
            return (
                <div className="form-group text-center">
                    <button className="btn btn-info btn-fill btn-wd" onClick={this.formikSubmit}>Update Details</button>
                </div>
            )
        }
    }

    render() {
        let { user } = this.props;
        return (
            <div>
                {this.state.successFullCreated ?
                    <div className='row justify-content-center align-items-center'>
                        <div className='col-sm-12 col-md-3 text-center mt-5'>
                            <div className='card'>
                                <div>
                                    <img src={congratulation} alt='..success' className='succesGif' />
                                </div>
                                <h4 className='mt-4 mb-4'>
                                    {/* <span >
                                    <FontAwesomeIcon icon={faCheckCircle}
                                        style={{ fontSize: 25, color: '#22b14c' }} /></span> */}
                                    <span className='pl-2'>
                                        Congratulations !
                                </span></h4>
                                <p className='text-secondary mb-4'><b>Great job, Your work is done here</b></p>
                                <Link to='/login'>
                                    <button className="btn btn-info btn-fill table_action_btn px-5 ml-auto"
                                        style={{ verticalAlign: 'bottom' }}
                                    >
                                        Click Here to Login
                            </button>
                                </Link>
                            </div>
                        </div>

                    </div>
                    : <div className="row d-flex justify-content-center">
                        <div className="col-12 col-md-6">
                            <div className="card">
                                <button className="btn btn-primary table_action_btn px-lg-5 px-md-1 ml-auto"
                                    style={{ verticalAlign: 'bottom' }}
                                    onClick={this.props.backAction}>
                                    Back
                            </button>
                                <div >
                                    <SimpleUserForm
                                        onSubmit={this.onSubmit}
                                        initialValues={user}
                                        options={{ ...this.state }}
                                        formikRef={this.formikRef}
                                        languageData={languageData}
                                    />
                                    {this.getActionBtns()}
                                </div>
                                {/* session expiry modal */}
                                <SessionExpiryModal
                                    isOpen={this.state.sessionExpiryModal}
                                    withoutBrach={true}
                                />
                            </div>
                        </div>
                    </div>}
            </div>
        )
    }
};

export default connect(
    state => ({
        user: state.user
    }),
    dispatch => ({
        login: bindActionCreators(loginActionForTrainComponent, dispatch),
        updateUserProfile: bindActionCreators(updateUserProfileAction, dispatch),
        getLanguageData: bindActionCreators(getLanguageDataAction, dispatch),
        successNotification: bindActionCreators(successNotificationAction, dispatch),
        clearData: bindActionCreators(clearStoreData, dispatch),
        getEntityDetails: bindActionCreators(getEntityDetails, dispatch)
    })
)(UserProfile);

