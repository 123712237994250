import React from "react";

const TnCComponent = () => {
	return (
		<div className="row terms-conditions-modal">
			<div className='col-12 t-c-title'>
				Krowd Limited Terms and Conditions
			</div>

			<div className='col-12 m-t-15'>
				These terms and conditions govern the access to, and use of, the marketplace offered by
				Krowd Limited at <a style={{ color: 'blue' }} href="https://www.krowdit.com" target="_blank" rel="noopener noreferrer">www.krowdit.com</a> by a Reward Partner.
			</div>

			<div className='col-12 m-t-10'>
				Krowd Limited <b>(“Krowd”)</b> is a company incorporated in England and Wales with registered number
				10027920 whose registered address is at 1 Fore Street Avenue, London, EC2Y 9DT (“Krowd”). At <a style={{ color: 'blue' }} href="https://www.krowdit.com" target="_blank" rel="noopener noreferrer">www.krowdit.com</a> by the
				Reward Partner.

			</div>

			<div className='col-12 m-t-10'>
				<b>1. Definitions</b>
			</div>
			<div className='col-12 m-t-10'>
				- <b>Cashback:</b> The relevant cashback offered to a Customer in an Offer.<br />
				- <b>Customer:</b> The customers of the Publisher, to whom the Publisher has provided Offers to.<br />
				- <b>Data Protection Laws:</b> Applicable legislation protecting personal data, including n particular the GDPR and the Data Protection Act 2018
				and (in each case) any replacement to it, together with binding guidance and codes of practice issued from time to time by relevant supervisory authorities.<br />
				- <b>GDPR:</b> Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection
				of natural persons with regard to the processing of personal data and on the free movement of such data
				including where applicable any local implementing laws as updated from time to time.<br />
				- <b>Success Fee:</b> The fee to be paid by the Reward Partner to Krowd for successful Offer Redemptions as submitted by the Reward Partner as part of any Offer submission.<br />
				- <b>Marketplace:</b> The Krowd customer acquisition marketplace at <a style={{ color: 'blue' }} href="https://www.krowdit.com" target="_blank" rel="noopener noreferrer">www.krowdit.com</a> where the Reward Partner places Offers.<br />
				- <b>Offer:</b> The cashback offers uploaded by the Reward Partner onto the Marketplace for the purchase of certain goods or services from the Reward Partner.<br />
				- <b>Offer Redemptions:</b> The purchase of goods and services by a Customer that is subject to an Offer.<br />
				- <b>Publisher:</b> Participating banks, financial partners, airlines, employee benefit platforms and other partners of Krowd who provide Offers to their end customers and users.<br />
			</div>

			<div className='col-12 m-t-10'>
				<b>2. Making an Offer Available</b>
			</div>
			<div className='col-12 m-t-10'>
				2.1) The Reward Partner shall make an Offer available through the Marketplace by completing the Offer submission
				on the Marketplace which shall include such information as is required for Krowd to host the Offer on the
				Marketplace, including target Customer group, qualifying offer criteria, total available offer budget as
				well as upload Reward Partner’s logo and any other images or content as may be reasonably required to
				enable the hosting of the Offer<br />
				2.2) The value of the Offer and the Success Fee is set out in clause 6 of these terms and conditions.<br />
				2.3) By making an Offer submission, the Reward Partner undertakes to honour such Offer for all and any
				purchases made by Customers who make an Offer Redemption.<br />
				2.4) Reward Partner Offer submissions may be amended by the Reward Partner at any point up until 30
				calendar days before the end of the month prior to the month in which the Offer is due to be run (the
				“Amendment Period”). Past this point, all amendments shall be at the discretion of Krowd.<br />
				2.5) Following successful completion of an Offer submission, Krowd shall confirm to the Reward Partner acceptance of the Offer along with relevant details, Krowd reserves the right to not approve any Offer
				or ask for reasonable amendments prior to such approval.<br />
				2.6) Krowd shall make the Offer available to a portfolio of Publishers, should the Reward Partner not want
				the Offer to be made with any specific Publishers then it must notify Krowd via <a style={{ color: 'blue' }} href="mailto:sales@krowdit.com">sales@krowdit.com</a> within
				the Amendment Period otherwise Krowd shall make the Offer available to all Publishers it deems fit. <br />
				2.7) Krowd may upload the Merchant IDs to the Marketplace up to 30 days before the Offer goes live in
				order to review transaction analytics.<br />
			</div>

			<div className='col-12 m-t-10'>
				<b>3. Offer Redemption</b>
			</div>
			<div className='col-12 m-t-10'>
				3.1) Where the goods being offered by the Reward Partner have a return period, Krowd shall invoice the Success Fee on completion the applicable return period (up to a maximum of 30 days).<br />
			</div>

			<div className='col-12 m-t-10'>
				<b>4. Data Protection</b>
			</div>
			<div className='col-12 m-t-10'>
				4.1) Krowd only stores the names and email addresses of the Reward Partner’s staff that log onto the Krowd Customer Acquisition Marketplace. Krowd does not store or process additional personal data from Reward Partners or from its Publishers. Examples of non-personal information include, but are not limited to, Customer IDs, Transaction IDs, and Demographic information.<br />
				4.2) The main purposes for which Krowd use non-personal information are:<br />
				<div className='col-12 m-t-10'>
					- Management and administrative purposes of your purchases through Krowd. <br />
					- Providing services tailored to you.<br />
					- Analysis and market research<br />
					- Updates and service communications<br />
					- Transaction monitoring<br />
				</div>
				4.3) For the remainder of this clause 4 the expressions "Data Controller", "Data Processor", "Data Subject", “Personal Data”, "Personal Data Breach" and "Process" have the meanings given to them in the Data Protection Laws.<br />
				4.4) Krowd must duly observe all its obligations under the Data Protection Laws which arise in connection with the provision of the services under the contract created by these terms and conditions and not do anything which puts the Reward Partner in breach of the Data Protection Laws.<br />
				4.5) Insofar as Krowd Processes Personal Data for the Reward Partner as a Data Processor, Krowd must:<br />
				<div className='col-12 m-t-10'>
					(i) process the Personal Data solely on the documented instructions of the Reward Partner, for the purposes of providing the services contemplated by these terms and conditions.<br />
					(ii) process only the types of Personal Data, relating to the categories of Data Subjects, and in the manner required to deliver the Services, and in the manner agreed by the parties.<br />
					(iii) take all measures required by Article 32 of the GDPR and/or any Policies to ensure the security of the Personal Data.<br />
					(iv) take reasonable steps to ensure the reliability of any staff who may have access to the Personal Data, and their treatment of the Personal Data as confidential.<br />
					(v) nottransferthePersonalDatatoanycountryoutsidetheEuropeanEconomicArea(EEA)without the prior written consent of the Reward Partner.<br />
					(vi) not permit any third party to Process the Personal Data without the prior written consent of the Reward Partner, such consent to be subject to Krowd meeting the conditions set out in Article 28 (2) and (4) of the GDPR.<br />
					(vii)promptly notify the Reward Partner of any communication from a Data Subject regarding the Processing of their Personal Data, or any other communication (including from a supervisory authority) relating to either party's obligations under the Data Protection Laws in respect of the Personal Data.<br />
					(viii)immediately upon becoming aware, and in any case within 24 hours, notify the Reward Partner of any Personal Data Breach, such notice to include all information reasonably required by the Reward Partner to comply with its obligations under the Data Protection Laws.<br />
					(ix) permit the Reward Partner, on reasonable prior notice, to inspect and audit the facilities and systems used by Krowd to Process the Personal Data, the technical and organisational measures used by Krowd to ensure the security of the Personal Data and any and all records maintained by Krowd relating to that Processing.<br />
					(x) provide any assistance reasonably requested by the Reward Partner in relation to (i) any communication received under clause 4.5vii, as well as any similar communication received by the Reward Partner directly; and (ii) any Personal Data Breach, including by taking any appropriate technical and organisational measures reasonably requested by the Reward Partner.<br />
					(xi) where requested by the Reward Parter, delete any Personal Data in accordance with the Reward
					Partner’s then current data retention policy; and <br />
					(xii) cease Processing the Personal Data immediately upon the termination or expiry of this agreement
					and at the Reward Partner's option either return, or securely delete the Personal Data.<br />
				</div>
			</div>
			<div className='col-12 m-t-10'>
				<b>5. Invoicing and Payment Terms</b>
			</div>
			<div className='col-12 m-t-10'>
				5.1) The Reward Partner shall pay Krowd the Success Fee and Cashback for all Offer Redemptions on a weekly basis.
				Krowd shall invoice the Reward Partner on a weekly basis through Direct Debit as provided via the GoCardless Direct Debit on the Marketplace.
				The invoice shall be payable within a calendar month. Late payments shall accrue interest at a rate of 3% above the base rate of Barclays Bank from time to time.<br />
				5.2) Deposit Scheme- Not Applicable.<br />
				5.3) Krowd undertakes to transfer the Cashback received from the Reward Partner to the relevant Publisher for payment to its Customers, pursuant to its relevant terms and conditions with that Publisher.<br />
				5.4) Should Krowd receive any data outside of the invoicing period that relates to Offer Redemptions, Krowd shall be entitled to invoice for such Offer Redemptions in the next invoice.<br />
			</div>

			<div className='col-12 m-t-10'>
				<b>6. Success Fee and Cashback Fee</b>
			</div>
			<div className='col-12 m-t-10'>
				6.1) The Success Fee shall be calculated based on the terms agreed between the Parties, whether agreed
				verbally, via email, or submitted through Krowd's Customer Acquisition Marketplace, for the value of
				Offer Redemptions.<br />
				6.2) The Cashback Fee shall be determined in accordance with the terms agreed between the Parties,
				whether agreed verbally, via email, or submitted through Krowd's Customer Acquisition Marketplace,
				for the value of Offer Redemptions.<br />
			</div>

			<div className='col-12 m-t-10'>
				<b>7. Enrolment with Publishers</b>
			</div>
			<div className='col-12 m-t-10'>
				7.1) Krowd will automatically promote offers from the Reward partner to a portfolio of exclusive Publishers that have access to customers. If you do not want to be automatically enrolled, and consequently want to select a Publisher of choice then the brand will need to opt-out.<br />
				7.2) Such requests should be communicated in writing to Krowd via <a style={{ color: 'blue' }} href="mailto:sales@krowdit.com">sales@krowdit.com</a>.<br />
			</div>
			<div className='col-12 m-t-10'>
				<b>8. Removing an Offer</b>
			</div>
			<div className='col-12 m-t-10'>
				8.1) The Offer shall remain on the Marketplace for the duration of the Offer, as selected by the Reward Partner during the Offer submission. 
				The Reward Partner may request for an Offer to end early by providing written notice to Krowd via <a href="mailto:sales@krowdit.com">sales@krowdit.com</a>. 
				Krowd shall, upon its discretion, remove the Offer by the end of the month following the month in which such notice is given. 
				Notwithstanding the foregoing, Krowd reserves the right to remove an Offer at any time in its complete discretion. 
				If the Reward partner decides to withdraw an offer during an ongoing 3-month Amex Dining campaign, the offer will remain active until the campaign's scheduled end date.<br />
			</div>

			<div className='col-12 m-t-10'>
				<b>9. Krowd’s role as an intermediary</b>
			</div>
			<div className='col-12 m-t-10'>
				9.1) Krowd acts solely as an intermediary, facilitating the presentation of Offers to the Publisher,
				who in turn makes these available directly to Customers. Each Offer Redemption forms a distinct
				contract between the Reward Partner and the Customer, for which the Reward Partner is solely
				responsible. This includes compliance with all applicable laws concerning the provision of goods
				or services and fulfillment of contracts. The Reward Partner indemnifies Krowd and its Publishers
				against any losses or claims arising from
				<div className='col-12 m-t-10'>
					(a) the Reward Partner's failuresto honor Offers; or (b)
					issues related to marketing materials provided to Krowd. Similarly, Krowd shall indemnify, defend
					and hold harmless the Reward Partner against any losses or claims resulting from (a) Krowd's failure
					to correctly disburse cashbacks as agreed; and (b) any action demand, claim or allegation that the
					Reward Partner's use of the Market Place or any other resources provided by or on behalf Krowd infringes
					any rights of any third party. Each party warrants that it possesses the necessary legal rights to all materials,
					including marketing content and logos, provided to the other party as part of the Offer's execution.
					9.2) Krowd is not responsible for Publishers subsequent marketing of the Offer and makes no representations or
					warranties about the extent to which an Offer is marketed, including whether it is marketed at all. Krowd is not
					responsible for any failure by a Publisher or itself to make an Offer available.<br />
				</div>
			</div>
			<div className='col-12 m-t-10'>
				<b>10. License</b>
			</div>
			<div className='col-12 m-t-10'>
				10.1) The Reward Partner grants Krowd and Publishers a revocable, royalty-free, non-exclusive, non- transferable,
				non-sublicensable licence to use the Reward Partner’s trade marks and other marketing material provided as part
				of the Offer submission by the Reward Partner through the Marketplace for the purposes of solely making the Offer
				available and to be used in Krowd’s marketing materials, including Krowd’s website.<br />
				10.2) Krowd hereby grants to the Reward Partner a worldwide, non-exclusive, Irrevocable licence to use the Marketplace.<br />
			</div>

			<div className='col-12 m-t-10'>
				<b>11. Liability</b>
			</div>
			<div className='col-12 m-t-10'>
				11.1) Both parties agree that nothing in these terms and conditions is intended to exclude or limit the
				liability of:
				<div className='col-12 m-t-10'>
					(a) either party for death or personal injury resulting from negligence.<br />
					(b) either party for fraud or fraudulent misrepresentation.<br />
					(c) eitherpartyforanyliabilitiesinanywaythatisnotpermittedunderapplicablelaw.<br />
					(d) exclude any liabilities that may not be excluded under applicable law, and<br />
					(e) Krowd for any Losses for which it provides and indemnity under the Agreement.<br />
				</div>
				11.2) Neither party shall be liable to the other in respect of
				<div className='col-12 m-t-10'>
					(a) any loss of profits or anticipated savings.<br />
					(b) any loss of respite or income.<br />
					(c) any loss of business, contracts, or opportunities, or<br />
					(d) any special, indirect, or consequential loss or damage.<br />
				</div>
				11.3) Subject to clauses 11.1 and 0, the maximum aggregate liability of the Reward Partner under and/or in connection with these
				Terms and Conditions (whether in contract, tort (including negligence) or otherwise), in respect of all acts, omissions
				(including negligence), breach of statutory duty or breach of warranty of the Reward Partner and/or their officers, employees,
				agents, contractors or subcontractors, shall not in the aggregate exceed the total fees paid by the Reward Partner.<br />
			</div>

			<div className='col-12 m-t-10'>
				<b>12. Miscellaneous</b>
			</div>
			<div className='col-12 m-t-10'>
				12.1) These terms come into effect on an Offer submission and remain in effect until that Offer has been completed.<br />
				12.2) Both parties shall keep the terms of the agreement and all and any underlying data shared or processed as part of the
				agreement confidential, save where required to disclose by law.<br />
				12.3) All notices may be given, in the case of Krowd to the address at the top of these terms and conditions and by email to
				<a style={{ color: 'blue' }} href="mailto:sales@krowdit.com" >sales@krowdit.com</a>, in the case of the Reward Partner, to the address and email provided as part of the Offer submission.<br />
				12.4) This agreement constitutes the entire agreement between the parties in relation to the subject matter it contains.<br />
			</div>

			<div className='col-12 m-t-10'>
				<b>13. Governing Law</b>
			</div>
			<div className='col-12 m-t-10'>
				13.1) These terms and conditions and all matters, including non-contractual obligations, arising hereunder shall be governed by the laws of
				England and Wales and each party irrevocably submits to the exclusive jurisdiction of the courts of England and Wales.<br />
			</div>
		</div>
	)
}

export default TnCComponent;
